// @ts-check
import { APPLICATION_META_STORE_ID } from '@/constants/storeIds';
import { reactive, computed } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { fetchApplicationByIdService } from '@/services/application-service/applicationRequests';
import { fetchModuleByIdService } from '@/services/application-service/moduleRequests';
import { fetchDatabaseByIdService } from '@/services/database-service/databaseRequests';
import {fetchAutoTestByIdService} from '@/services/autotest-service/moduleTestRequests';

//-- composable sub stores definition start --//
const useBreadCrumbs = () => {
    const breadcrumbsMeta = reactive({
        application: {
            name: null,
            id: null,
        },
        module: {
            name: null,
            id: null,
        },
        database: {
            name: null,
            id: null,
        },
        table: {
            name: null,
            id: null
        },
        test: {
            name: null,
            id: null
        }
    });
    const getCurrentApplication = computed(() => breadcrumbsMeta.application);
    const getCurrentDatabase = computed(() => breadcrumbsMeta.database);
    const getCurrentModule = computed(() => breadcrumbsMeta.module);
    const getCurrentTable = computed(() => breadcrumbsMeta.table);
    const getCurrentTest = computed(() => breadcrumbsMeta.test);
    /**
     * @param {import('vue-router').Route} payload 
     */
    const updateBreadCrumbsMeta = async (payload) => {
        try {
            if (payload.params.appId) {
                const { data: { data } } = await fetchApplicationByIdService(payload.params.appId);
                breadcrumbsMeta.application.name = data.name;
                breadcrumbsMeta.application.id = payload.params.appId;
            }
            if (payload.params.moduleId) {
                const { data: { data } } = await fetchModuleByIdService(payload.params.appId, payload.params.moduleId);
                breadcrumbsMeta.module.name = data.name;
                breadcrumbsMeta.module.id = payload.params.moduleId;
            }
            if (payload.params.databaseId) {
                const { data: { data } } = await fetchDatabaseByIdService(payload.params.databaseId);
                breadcrumbsMeta.database.name = data.name;
                breadcrumbsMeta.database.id = payload.params.databaseId;
            }
            if (payload.params.tableId) {
                breadcrumbsMeta.table.name = payload.params.tableId;
                breadcrumbsMeta.table.id = payload.params.tableId;
            }
            if (payload.params.testId) {
                const { data: { data } } = await fetchAutoTestByIdService(payload.params.testId);
                breadcrumbsMeta.test.name = data.name;
                breadcrumbsMeta.test.id = payload.params.testId;
            }
        } catch (err) {
            console.error(err);
        }
    };

    return {
        breadcrumbsMeta,
        getCurrentApplication,
        getCurrentDatabase,
        getCurrentModule,
        getCurrentTable,
        getCurrentTest,
        updateBreadCrumbsMeta
    };
};

export const useBreadCrumbsList = (deps) => {
    const { breadCrumbsSubStore } = deps;
    const { getCurrentApplication, getCurrentModule, getCurrentDatabase, getCurrentTable, getCurrentTest } = breadCrumbsSubStore;
    
    const appDetailLink = computed(() => `/application/${getCurrentApplication.value.id}`);
    const moduleDetailLink = computed(() => `/module/${getCurrentModule.value.id}`);
    const databaseDetailLink = computed(() => `/database/${getCurrentDatabase.value.id}`);
    const tableDetailLink = computed(() => `/table/${getCurrentTable.value.id}`);

    const applicationBreadCrumbs = computed(() => ({
        applications: {
            label: 'Applications',
            link: null
        },
        settings: {
            label: 'Settings',
            link: null
        },
        layouts: {
            label: 'Layouts',
            link: `${appDetailLink.value}/layouts`
        },
        layout: {
            label: 'Layout',
            link: null
        },
        environmentVariables: {
            label: 'Environment Variables',
            link: null
        },
        monitoring: {
            label: 'Monitoring',
            link: null
        },
        analytics: {
            label: 'Analytics',
            link: null
        }
    }));
    const moduleBreadCrumbs = computed(() => ({
        modules: {
            label: 'Modules',
            link: null
        },
        list: {
            label: getCurrentApplication.value.name,
            link: `${appDetailLink.value}/modules`
        },
        edit: {
            label: getCurrentModule.value.name,
            link: `${appDetailLink.value}${moduleDetailLink.value}/edit`
        }
    }));
    const databaseBreadCrumbs = computed(() => ({
        databases: {
            label: 'Databases',
            link: null
        },
        list: {
            label: getCurrentApplication.value.name,
            link: `${appDetailLink.value}/databases`
        },
        detail: {
            label: getCurrentDatabase.value.name,
            link: `${appDetailLink.value}${databaseDetailLink.value}`
        }
    }));
    const tableBreadCrumbs = computed(() => ({
        tables: {
            label: 'Tables',
            link: null
        },
        detail: {
            label: getCurrentTable.value.name,
            link: `${databaseDetailLink.value}${tableDetailLink.value}/data`
        },
        data: {
            label: 'Data',
            link: null
        },
        structureDetail: {
            label: getCurrentTable.value.name,
            link: `${databaseDetailLink.value}${tableDetailLink.value}/structure`
        },
        structure: {
            label: 'Structure',
            link: null
        }
    }));
    const testCenterCrumbs = computed(() => ({
        testCenter: {
            label: 'Test Center',
            link: null
        },
        list: {
            label: getCurrentTest.value.name,
            link: `/test-center/${getCurrentTest.value.id}`
        },
    }));
    const userBreadCrumbs = computed(() => ({ 
        users: {
            label: 'Users',
            link: null
        }
    }));
    const auditLogBreadCrumbs = computed(() => ({
        auditLog: {
            label: 'Audit log',
            link: null
        }
    }));
    const versionBreadCrumbs = computed(() => ({
        versions: {
            label: 'Versions',
            link: null
        }
    }));

    return {
        applicationBreadCrumbs,
        moduleBreadCrumbs,
        databaseBreadCrumbs,
        tableBreadCrumbs,
        userBreadCrumbs,
        auditLogBreadCrumbs,
        versionBreadCrumbs,
        testCenterCrumbs
    };
};
//-- composable sub stores definition end --//

export const useApplicationMetaStore = defineStore(APPLICATION_META_STORE_ID, () => {
    // compose sub stores
    const breadCrumbsSubStore = useBreadCrumbs();
    const breadCrumbsListSubStore = useBreadCrumbsList({
        breadCrumbsSubStore
    });

    return {
        ...breadCrumbsSubStore,
        ...breadCrumbsListSubStore
    };
});

