import Vue from 'vue';
import App from './App.vue';
import { pinia } from '@/plugins/pinia';
import VueSession from 'vue-session';
import router from './router';
import UUID from 'vue-uuid';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueJwtDecode from 'vue-jwt-decode';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
import VueCookies from 'vue-cookies';
import VuePortal from '@linusborg/vue-simple-portal';
import VueSignaturePad from 'vue-signature-pad';



/** style **/
import Buefy from 'buefy';
// import '@/lib/drawflow/drawflow.css';
import '@/style/global.scss';
import '@/style/bulma.scss';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Buefy);
/** end style **/

const socket = io(process.env.VUE_APP_APP_SERVICE_DOMAIN);
Vue.use(VueSocketIOExt, socket);

Vue.config.productionTip = false;
Vue.use(UUID);
Vue.use(VueAxios, axios);
Vue.use(VueSession, {
    persist: true
});
Vue.use(VueJwtDecode);
Vue.use(VueLodash, { lodash: lodash });
Vue.use(VueCookies);
Vue.use(VuePortal, {
    name: 'teleport'
});

Vue.use(VueSignaturePad);

new Vue({
    pinia,
    router,
    render: h => h(App)
}).$mount('#app');
