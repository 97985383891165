export const APPLICATION_SERVICE_ENDPOINT = process.env.VUE_APP_APPLICATION_SERVICE_DOMAIN;

// Resource paths
export const APPLICATION_RESOURCE_PATH = '/application';
export const MODULE_RESOURCE_PATH = '/module';
export const MODULE_PARSE_RESOURCE_PATH = '/parse';
export const VARIABLE_RESOURCE_PATH = '/variable';
export const ENVIRONMENT_VARIABLE_RESOURCE_PATH = '/env_variable';
export const LAYOUT_RESOURCE_PATH = '/layout';
export const NODE_RESOURCE_PATH = '/node';
export const MODULE_DOCUMENTS_RESOURCE_PATH = '/documents';
export const GLOBAL_VARIABLES_RESOURCE_PATH = '/global-variables';
export const COPY_LAYOUT_RESOURCE_PATH = '/copy';
export const REVERT_RESOURCE_PATH = '/revert';
export const AUTH_MODULE_RESOURCE_PATH = '/auth-module';
export const GUARD_MODULE_RESOURCE_PATH = '/guard';
export const UNGUARD_MODULE_RESOURCE_PATH = '/unguard';