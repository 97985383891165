// route names
export const DASHBOARD_ROUTE_NAME = 'Dashboard';
export const APPLICATION_ROUTE_NAME = 'Application';
export const DATABASE_ROUTE_NAME = 'Database';
export const DATABASE_TABLES_ROUTE_NAME = 'Database Tables';
export const DATABASE_TABLE_DATA_ROUTE_NAME = 'Database Table Data';
export const DATABASE_TABLE_STRUCTURE_ROUTE_NAME = 'Database Table Structure';
export const APPLICATION_LAYOUTS_ROUTE_NAME = 'Application Layouts';
export const APPLICATION_SINGLE_LAYOUT_ROUTE_NAME = 'Single Layout';
export const APPLICATION_SETTINGS_ROUTE_NAME = 'Application Settings';
export const APPLICATION_ENVIRONMENT_VARIABLES_ROUTE_NAME = 'Application Environment Variables';
export const TEST_CENTER_ROUTE_NAME = 'Test Center';
export const APPLICATION_BUILDER_ROUTE_NAME = 'Application Builder';
export const APPLICATION_BUILDER_EDIT_ROUTE_NAME = 'Application Builder Edit';
export const USERS_ROUTE_NAME = 'Users';
export const NEW_USER_ROUTE_NAME = 'New User';
export const EDIT_USER_ROUTE_NAME = 'Edit User';
export const AUDIT_LOG_LIST_ROUTE_NAME = 'AuditLogList';
export const AUDIT_LOG_SINGLE_ROUTE_NAME = 'AuditLogSingle';
export const PREVIEW_ROUTE_NAME = 'Preview';
export const VERSION_ROUTE_NAME = 'Version';
export const LOGIN_ROUTE_NAME = 'Login';
export const FORGOT_PASSWORD_ROUTE_NAME = 'ForgottenPassword';
export const RESET_PASSWORD_ROUTE_NAME = 'ResetPassword';
export const LOGIN_CONFIRM_ROUTE_NAME = 'TwoFactorCode';
export const MONITORING_ROUTE_NAME = 'Monitoring';
export const ANALYTICS_ROUTE_NAME = 'Analytics';
